<template>
  <!-- Main modal -->
  <div
    v-if="store.isVisible"
    tabindex="-1"
    role="dialog"
    aria-modal="true"
    aria-labelledby="reimbursementModalLabel"
    aria-describedby="reimbursementModalDescription"
    class="fixed left-0 right-0 top-0 z-50 flex h-full max-h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-30 p-4 md:inset-0">
    <div class="relative max-h-full w-full max-w-md">
      <!-- Modal content -->
      <div class="relative rounded-lg bg-white p-2 shadow">
        <!-- Modal header -->
        <div class="flex items-start justify-between">
          <button
            type="button"
            class="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-900 hover:bg-gray-100"
            @click="store.toggleModal">
            <SvgoXMark class="text-2xl" />
            <span class="sr-only">Schließen</span>
          </button>
        </div>
        <h2 id="reimbursementModalLabel" class="p-4 pt-0 text-xl">Wir halten deine Kosten gering</h2>
        <p id="reimbursementModalDescription" class="p-4 pt-0 text-base text-gray-900">
          Mit unserem smarten Rückerstattungssystem holen wir das meiste für dich heraus: Als Kassenpatient:in bekommst
          du <strong>bis zu {{ store.reimbursement }} % der Kosten zurück</strong> (abhängig von Fachrichtung,
          Leistungen, Anzahl der Arztbesuche pro Quartal etc.). Wir reichen direkt elektronisch für dich ein und du
          erhältst deine Rückerstattung automatisch. <br />Bist du <strong>privat versichert?</strong> Dann bekommst du
          meist <strong>die kompletten Kosten zurück.</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useReimbursementModalStore } from "~/store/reimbursementModal";

const store = useReimbursementModalStore();
</script>

<style lang="scss" scoped></style>
